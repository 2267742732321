import React from "react"

import Layout from "../components/layout"

import HeroSection from '../components/hero'
import SummarySection from '../components/summary'
import TargetSection from '../components/target'
import SpeakersSection from '../components/speakers'
import AgendaSection from '../components/agenda'
import DateSection from '../components/date'
import PartnerSection from '../components/partner'
import ContactSection from '../components/contact'
import CookieBar from '../components/cookieBar'

import SEO from "../components/seo"


const IndexPage = () => (
  <Layout>
    <SEO title="Workshop" description="Registrujte se na náš odborný workshop, který je zdarma a kde vám experti na monetizaci dat představí jejich „best practice“ a know-how." />
    <HeroSection></HeroSection>
    <SummarySection></SummarySection>
    <TargetSection></TargetSection>
    <SpeakersSection></SpeakersSection>
    <AgendaSection></AgendaSection>
    <DateSection></DateSection>
    <PartnerSection></PartnerSection>
    <ContactSection></ContactSection>
    <CookieBar></CookieBar>
  </Layout>
)

export default IndexPage
