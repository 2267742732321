import React from "react"

import styled from 'styled-components'
import * as style from '../components/style'

import Button from "../components/button"

import Diagram from "../images/diagram.inline.svg"

const DiagramWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: ${style.spacing.large};
  opacity: 1;
  width: 50%;
  display: none;
  @media screen and (min-width: 1160px) {
    display: block;
  }
  svg {
    path {
      fill: ${style.colors.action};
    }
    .fill {
      fill: ${style.colors.action};
    }
    .stroke {
      stroke: ${style.colors.action};
    }
  }
  svg > g > g {
      opacity: .15;
  }
  #dwh {
    animation: hideshow 15s ease infinite;
  }
  #web {
    animation: hideshow 15s 2s ease infinite;
  }
  #internet {
    animation: hideshow 15s 4s ease infinite;
  }
  #communication {
    animation: hideshow 15s 6s ease infinite;
  }
  #revenue {
    animation: hideshow 15s 8s ease infinite;
  }
  #presentation {
    animation: hideshow 15s 10s ease infinite;
  }
  #meassurmenthub {
    animation: hideshow 15s 12s ease infinite;
  }
`;

const Hero = styled.div`
  background: ${style.colors.primary};
  background: radial-gradient(circle, ${style.colors.primary} 0%, #162648 80%, #152648 100%);
  width: 100%;
  color: ${style.colors.grey};
  text-align: left;
  padding: 5rem 0;
  position: relative;
  p {
    margin: ${style.spacing.basic} auto;
    font-size: 1.2rem;
    color: ${style.colors.grey};
    line-height: ${style.lineHeight.basic};
    @media screen and (min-width: 1000px) {
      font-size: 1.1rem;
    }
    @media screen and (min-width: 1400px) {
      font-size: 1.2rem;
    }
  }
  .section {
    width: 100%;
    @media screen and (min-width: 1160px) {
      width: 47%;
      margin: 0 0 0 80px;
    }
  }
`;

const Headline = styled.h1`
  color: ${style.colors.light};
  text-align: left;
  font-size: 3rem;
  margin: 0 0 ${style.spacing.large};
`;

const HeroSection = () => (
  <Hero>
    <DiagramWrapper><Diagram /></DiagramWrapper>
    <div className="section">
      <Headline>Monetizace dat v&nbsp;praxi</Headline>
      <p>Data budou pro odlišení se čím dál důležitější. Již nebudou jen statická, ale postupně je nahrazují toky z&nbsp;vícero zdrojů, kombinované a&nbsp;vytěžované v&nbsp;reálném čase. Velké množství dat se stává cenným zdrojem informací, díky kterým lépe pochopíme naše zákazníky, zlepšíme produkt a&nbsp;procesy a&nbsp;v&nbsp;neposlední řadě můžeme díky datům vydělat. Registrujte se na náš odborný workshop, který je zdarma a&nbsp;kde vám experti na monetizaci dat představí jejich „best practice“ a&nbsp;know-how.</p>
      <Button link="https://forms.office.com/Pages/ResponsePage.aspx?id=V6D2m_hyZE-C1lFmt5tCOvms3qGhLrZJvTl8UDPUa4JURDcwN1lHRUpOTEdHQzZTOFoyRjRXVEI2Sy4u" text="Stáhnout e-book" />
    </div>
  </Hero>
)

export default HeroSection