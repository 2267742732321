import React from "react"

import styled from 'styled-components'
import * as style from '../components/style'

const Target = styled.div`
  background: ${style.colors.action};
  width: 100%;
  color: ${style.colors.primary};
  text-align: center;
  padding: ${style.spacing.large} 0 4rem;
  position: relative;
  margin-top: 20px;
  &::before {
      position: absolute;
      content: " ";
      left: 0px;
      bottom: 100%;
      border-left: 100vw solid rgb(248,248,248);
      border-right: 100vw solid transparent;
      border-bottom: 4vw solid ${style.colors.action};
  }
  p {
    margin: 0;
    max-width: 500px;
    margin: 0 auto;
    font-size: 1.2rem;
    color: ${style.colors.text};
    line-height: ${style.lineHeight.basic};
  }
`;

const Title = styled.h2`
  ${style.subHeading}
  color: ${style.colors.primary};
`;

const TargetSection = () => (
  <Target>
    <div className="section">
      <Title>Pro koho je workshop určen? </Title>
      <p>Workshop je určen primárně pro C-level managementu firmy, vyšší management a manažery, kteří přichází do styku s daty nebo ve své firmě řídí datové či marketingové oddělení. </p>
    </div>
  </Target>
)

export default TargetSection