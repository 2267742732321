import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import styled from 'styled-components'
import * as style from '../components/style'

const Speakers = styled.div`
  width: 100%;
  color: ${style.colors.light};
  padding: ${style.spacing.large} 0;
`;

const Title = styled.h2`
  ${style.subHeading}
`;

const SpeakersList = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto;
`;

const Speaker = styled.li`
  width: 100%;
  padding: ${style.spacing.basic};
  display: flex;
  flex-wrap: wrap;
  @media screen and (min-width: 600px) {
    flex-wrap: nowrap;
  }
  h4, p, div {
    color: ${style.colors.primary};
  }
`;

const Position = styled.div `
  font-size: .8rem;
  margin-top: 4px;
  margin-bottom: ${style.spacing.small}
`;

const Description = styled.p`
  font-size: .9rem;
  line-height: 1.5;
`;

const ImageWrapper = styled.div`
  width: 150px;
  min-width: 150px;
  height: 150px;
  border-radius: 30px;
  overflow: hidden;
  img {
    max-width: 100%;
  }
`;

const Content = styled.div`
  width: 100%;
  margin-top: ${style.spacing.basic};
  @media screen and (min-width: 600px) {
    width: fit-content;
    padding-left: ${style.spacing.basic};
  }
  h4 {
    margin-bottom: 0;
  }
`;

const Image = ( {name, alt} ) => {
  const data = useStaticQuery(graphql`
    query {
      hornych: file(relativePath: { eq: "hornych.jpg" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      voda: file(relativePath: { eq: "voda.jpg" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      randus: file(relativePath: { eq: "randus.jpg" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return <Img fixed={data[name].childImageSharp.fixed} alt={alt} objectFit="contain" />
}

const SpeakersSection = () => (
  <Speakers>
    <div className="section">
      <Title>Lektoři</Title>
      <SpeakersList>
        <Speaker>
          <ImageWrapper>
            <Image name="hornych" alt="Jan Hornych" />
          </ImageWrapper>
          <Content>
            <h4>Jan Hornych</h4>
            <Position>Head of Marketing Automation | Cross Masters</Position>
            <Description>Honza je specialistou v oblasti Decision Science. Profesně se zabývá vývojem systémů na podporu a automatizaci marketingu. Má více než 15leté zkušenosti z práce na projektech pro zahraniční i tuzemské internetové firmy.</Description>
          </Content>
        </Speaker>
        <Speaker>
          <ImageWrapper>
            <Image name="voda" alt="Vladimír Voda" />
          </ImageWrapper>
          <Content>
            <h4>Vladimír Voda</h4>
            <Position>CEO & konzultant | WATER MEDIA</Position>
            <Description>Vladimír se pohybuje v on-line marketingu 10 let. V poslední době se zaměřuje na efektivní multikanálovou komunikaci se zákazníky. Vede agenturu Water Media, která pomáhá e-shopům s efektivní správou inteligentního e-mail marketingu a automatizovanou multikanálovou komunikací.</Description>
          </Content>
        </Speaker>
        <Speaker>
          <ImageWrapper>
            <Image name="randus" alt="Jan Randus" />
          </ImageWrapper>
          <Content>
            <h4>Jan Randus</h4>
            <Position>Customer Success Manager | Roivenue</Position>
            <Description>Honza začínal v oblasti výkonnostního online marketingu. V Roivenue díky datové integraci a data-driven atribuci pomáhá marketingovým manažerům s vyhodnocováním multikanálových prodejních aktivit a s řízením a optimalizací marketingových rozpočtů.</Description>
          </Content>
        </Speaker>
      </SpeakersList>
    </div>
  </Speakers>
)

export default SpeakersSection