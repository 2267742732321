import React from "react"

import styled from 'styled-components'
import * as style from '../components/style'

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = ( {name, alt} ) => {
  const data = useStaticQuery(graphql`
    query {
      microsoft: file(relativePath: { eq: "microsoft2.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      crossmasters: file(relativePath: { eq: "crossmasters.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      watermedia: file(relativePath: { eq: "watermedia.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return <Img fixed={data[name].childImageSharp.fixed} alt={alt} objectFit="contain" />
}

const Partners = styled.div`
  width: 100%;
  text-align: center;
  padding: 4rem 0;
  transform: skew(0, -2deg);
  background: ${style.colors.lightGrey};
  .section {
    transform: skew(0, 2deg);
  }
`;

const Title = styled.h2`
  ${style.subHeading}
`;

const PartnersList = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: center;
  margin: 0;
  flex-wrap: wrap;
`;

const Partner = styled.li`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 ${style.spacing.small};
  margin-bottom: ${style.spacing.basic};
  @media screen and (min-width: 500px) {
    width: 50%;
    margin-bottom: ${style.spacing.basic};
  }
  @media screen and (min-width: 800px) {
    width: 33.3333%;
    margin-bottom: 0;
  }
  .gatsby-image-wrapper {
    width: 200px;
    height: 80px;
    margin: 0 auto;
    overflow: hidden;
    img {
      width: 100% !important;
      height: auto !important;
    }
  }
`;

const ImageWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PartnerTitle = styled.h3`
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: .9rem;
  margin-top: ${style.spacing.small};
`;

const PartnerSection = () => (
  <Partners>
    <div className="section">
      <Title>Partneři</Title>
      <PartnersList>
        <Partner>
          <ImageWrapper>
            <Image name="crossmasters" alt="Cross Masters"/>
          </ImageWrapper>
          <PartnerTitle>Specialisté na marketingová data</PartnerTitle>
        </Partner>
        <Partner>
          <ImageWrapper>
            <Image name="watermedia" alt="Water Media"/>
          </ImageWrapper>
          <PartnerTitle>Konzultanti emailového marketingu</PartnerTitle>
        </Partner>
        <Partner>
          <ImageWrapper>
            <Image name="microsoft" alt="Microsoft"/>
          </ImageWrapper>
          <PartnerTitle>Technologie pro vaše data</PartnerTitle>
        </Partner>
      </PartnersList>
    </div>
  </Partners>
)

export default PartnerSection