import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import styled from 'styled-components'
import * as style from './style'

import Button from "../components/button"

const Date = styled.div`
  width: 100%;
  text-align: center;
  padding: ${style.spacing.large} 0;
`;

const Title = styled.h2`
  ${style.subHeading}
  margin-top: ${style.spacing.basic};
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.li`
  padding: 0;
  width: 100%;
  padding: ${style.spacing.basic};
  @media screen and (min-width: 800px) {
    width: 50%;
  }
`;

const Image = ( {name} ) => {
  const data = useStaticQuery(graphql`
    query {
      prahaImage: file(relativePath: { eq: "praha.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bratislavaImage: file(relativePath: { eq: "bratislava.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return <Img fluid={data[name].childImageSharp.fluid} />
}


const ItemImage = styled.div`
  margin-bottom: ${style.spacing.basic};
  img {
    display: block;
  }
`;

const ItemTitle = styled.div`
  margin-bottom: ${style.spacing.small};
`;

const City = styled.h3`
  color: ${style.colors.primary};
  font-weight: 700;
  display: inline-block;
`;

const DateTime = styled.span`
  color: ${style.colors.text};
  font-weight: 400;
  display: inline-block;
  margin-left: ${style.spacing.small};
`;

const ItemDetail = styled.p`
  margin-bottom: ${style.spacing.basic};
  min-height: 50px;
`;

const NoDatesNotice = styled.div`
  padding: ${style.spacing.large};
  background-color: ${style.colors.primary};
  max-width: 700px;
  margin: 0 auto ${style.spacing.basic};
  color: white;
  font-size: 120%;
  line-height: 1.5;
`;

const WhiteButton = styled.a`
  background-color: white;
  padding: ${style.spacing.small} ${style.spacing.basic};
  margin: ${style.spacing.basic} 0 0;
  font-weight: bold;
  display: inline-block;
  text-decoration: none;
  color: ${style.colors.primary};
`;

const DateSection = () => (
  <Date>
    <div className="section">
      <Title>Termín a místo konání workshopu</Title>
      <NoDatesNotice>
        <p>Aktuálně nejsou vypsané žádné budoucí termíny workshopu Monetizace dat v praxi. Pro zájemce o téma <strong>doporučujeme e-book</strong>, který z workshopu vychází. Rádi vám ho zašleme.
        </p><Button link="https://forms.office.com/Pages/ResponsePage.aspx?id=V6D2m_hyZE-C1lFmt5tCOvms3qGhLrZJvTl8UDPUa4JURDcwN1lHRUpOTEdHQzZTOFoyRjRXVEI2Sy4u" text="Chci e-book"></Button>
      </NoDatesNotice>
      <List>
        <Item>
          <ItemImage>
            <Image name="prahaImage" />
          </ItemImage>
          <ItemTitle>
            <City>Praha <DateTime>8.11.2019</DateTime></City>
          </ItemTitle>
          <ItemDetail>9:30 - 12:00 Microsoft, Vyskočilova 1561/4a, 140 00 Praha 4</ItemDetail>
          <button disabled className="disabled">Chci se přihlásit na tento termín</button>
        </Item>
        <Item>
          <ItemImage>
            <Image name="bratislavaImage" />
          </ItemImage>
          <ItemTitle>
            <City>Bratislava <DateTime>14.11.2019</DateTime></City>
          </ItemTitle>
          <ItemDetail>13:30 - 16:00 Apollo Business Center II E/3p, Prievozská 4D, 821 09 Bratislava-Ružinov</ItemDetail>
          <button disabled className="disabled">Chci se přihlásit na tento termín</button>
        </Item>
      </List>

    </div>
  </Date>
)

export default DateSection