import React from "react"

import styled from 'styled-components'
import * as style from '../components/style'

const StyledButton = styled.a`
  background: ${style.colors.action};
  color: ${style.colors.primary};
  padding: ${style.spacing.small} ${style.spacing.basic};
  margin: ${style.spacing.basic} 0;
  font-weight: bold;
  display: inline-block;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
`;

const Button = ( {link, id, text, buttonState} ) => (
  <StyledButton href={`${link}`} id={`${id}`}>{text}</StyledButton>
)


export default Button
