import React from "react"

import styled from 'styled-components'
import * as style from '../components/style'

import IconUvod from "../images/icon-uvod.inline.svg"
import IconTechnologie from "../images/icon-technologie.inline.svg"
import IconTrendy from "../images/icon-trendy.inline.svg"
import IconNavratnost from "../images/icon-navratnost.inline.svg"


const Agenda = styled.div`
  width: 100%;
  color: ${style.colors.light};
  padding: 4rem 0;
  background: ${style.colors.lightGrey};
  transform: skew(0, -3deg);
  .section {
    transform: skew(0, 3deg);
  }
`;

const Title = styled.h2`
  ${style.subHeading}
`;

const List = styled.ol`
  list-style: none;
  margin: 0 auto;
  max-width: 700px;
`;

const Item = styled.li`
  padding: 0;
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
  margin-bottom: ${style.spacing.large};
  &:last-child {
    margin-bottom: 0;
  }
`;

const ItemTitle = styled.h3`
  color: ${style.colors.primary};
  margin: 0;
`;

const ItemIcon = styled.div`
  width: 100px;
  min-width: 100px;
  margin-right: ${style.spacing.basic};
  svg {
    width: 100%;
  }
`;

const DescriptionWrapper = styled.div`
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const ItemDescription = styled.ul`
  margin-top: ${style.spacing.small};
  li {
    margin-bottom: ${style.spacing.smallHalf};
    color: ${style.colors.text};
  }
`;

const AgendaSection = () => (
  <Agenda>
    <div className="section">
      <Title>Program workshopu</Title>
      <List>
        <Item>
          <ItemIcon><IconUvod /></ItemIcon>
          <DescriptionWrapper>
            <ItemTitle>Úvod – Proč monetizovat data?</ItemTitle>
          </DescriptionWrapper>
        </Item>
        <Item>
          <ItemIcon><IconTechnologie /></ItemIcon>
          <DescriptionWrapper>
            <ItemTitle>Marketingové technologie datové monetizace 2020</ItemTitle>
            <ItemDescription>
              <li>Propojení online a offline (datového) světa.</li>
              <li>Útoky na bidding engine a měření webových dat.</li>
              <li>Jak závisí rychlost odezvy a akce u nabídky dat.</li>
            </ItemDescription>
          </DescriptionWrapper>
        </Item>
        <Item>
          <ItemIcon><IconTrendy /></ItemIcon>
          <DescriptionWrapper>
            <ItemTitle>Trendy monetizace dat</ItemTitle>
            <ItemDescription>
              <li>E-mailová rozesílka nad daty z věrnostních programů.</li>
              <li>Prodej reklamy v newsletterech.</li>
              <li>Prodej zákaznických audiencí (cookies).</li>
            </ItemDescription>
          </DescriptionWrapper>
        </Item>
        <Item>
          <ItemIcon><IconNavratnost /></ItemIcon>
          <DescriptionWrapper>
            <ItemTitle>Vyhodnocené návratnosti investic a realtime data</ItemTitle>
            <ItemDescription>
              <li>Hodnocení efektivity kampaně.</li>
              <li>Vyhodnocené všech částí marketingu, tedy marketingu „jako takového“.</li>
              <li>Měsíční reporting není realtime přístup.</li>
            </ItemDescription>
          </DescriptionWrapper>
        </Item>
      </List>
    </div>
  </Agenda>
)

export default AgendaSection