import React from "react"

import styled from 'styled-components'
import * as style from '../components/style'

import PhoneIcon from "../images/phone.inline.svg"
import MailIcon from "../images/mail.inline.svg"

const Contact = styled.div`
  width: 100%;
  padding: ${style.spacing.large} 0 5rem;
`;

const Title = styled.h2`
  ${style.subHeading}
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Column = styled.div`
  width: 100%;
  margin-bottom: ${style.spacing.basic};
  @media screen and (min-width: 800px) {
    width: 45%;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  p {
    color: ${style.colors.primary};
    line-height: 1.4;
  }
`;

const Info = styled.a`
  padding: 0;
  display: flex;
  align-items: center;
  color: ${style.colors.primary};
  svg {
    width: 25px;
    margin-right: ${style.spacing.small};
  }
`;


const ContactSection = () => (
  <Contact>
    <div className="section">
      <Title>Kontakt</Title>
      <Content>
        <Column>
          <p>V případe dotazu nás prosím neváhajte kontaktovat</p>
          <ul>
            <Info href="tel:+420720941756"><PhoneIcon />+420 720 941 756</Info>
            <Info href="mailto:info@crossmasters.com"><MailIcon />info@crossmasters.com</Info>
          </ul>
        </Column>
        <Column>
          <p>Chcete se stát sponzorem akce? Měli byste zájem uspořádat workshop ve vaší firmě? Zavolajte nám</p>
          <ul>
            <Info href="tel:+420720941756"><PhoneIcon />+420 720 941 756</Info>
          </ul>
        </Column>
      </Content>
    </div>
  </Contact>
)

export default ContactSection