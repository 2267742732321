import React from "react"

import styled from 'styled-components'
import * as style from '../components/style'

import Icon1 from "../images/icon1.inline.svg"
import Icon2 from "../images/icon2.inline.svg"
import Icon3 from "../images/icon3.inline.svg"
import Icon4 from "../images/icon4.inline.svg"
import Icon5 from "../images/icon5.inline.svg"
import Icon6 from "../images/icon6.inline.svg"

const Summary = styled.div`
  width: 100%;
  color: ${style.colors.light};
  text-align: center;
  padding: ${style.spacing.large} 0;
  background: ${style.colors.lightGrey};
  position: relative;

`;

const Title = styled.h2`
  ${style.subHeading}
`;

const SummaryList = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
`;

const SummaryItem = styled.li`
  width: 100%;
  max-width: 400px;
  margin-bottom: ${style.spacing.basic};
  display: flex;
  @media screen and (min-width: 600px) {
    width: 50%;
    &:nth-child(1) {
      padding-right: ${style.spacing.small};
    }
    &:nth-child(2) {
      padding-left: ${style.spacing.small};
    }
    &:nth-child(3) {
      padding-right: ${style.spacing.small};
    }
    &:nth-child(4) {
      padding-left: ${style.spacing.small};
    }
    &:nth-child(5) {
      padding-right: ${style.spacing.small};
    }
    &:nth-child(6) {
      padding-left: ${style.spacing.small};
    }
  }
  @media screen and (min-width: 1000px) {
    width: 33.33%;
    &:nth-child(1) {
      padding-right: ${style.spacing.small};
    }
    &:nth-child(2) {
      padding-left: ${style.spacing.smallHalf};
      padding-right: ${style.spacing.smallHalf};
    }
    &:nth-child(3) {
      padding-left: ${style.spacing.small};
      padding-right: 0;
    }
    &:nth-child(4) {
      padding-right: ${style.spacing.small};
      padding-left: 0;
    }
    &:nth-child(5) {
      padding-left: ${style.spacing.smallHalf};
      padding-right: ${style.spacing.smallHalf};
    }
    &:nth-child(6) {
      padding-left: ${style.spacing.small};
    }
  }
`;

const SummaryContent = styled.div`
  padding: ${style.spacing.large} ${style.spacing.basic};
  color: ${style.colors.primary};
  border: 1px solid rgb(203, 203, 203);
  border-radius: 20px;
  h3 {
    margin-bottom: ${style.spacing.small};
  }
  p {
    line-height: 1.4;
  }
`;

const IconContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${style.spacing.small};
  svg {
    width: 60px;
    path {
      fill: ${style.colors.action};
    }
  }
`;

const SummarySection = () => (
  <Summary>
    <div className="section">
      <Title>Co vás čeká?</Title>
      <SummaryList>
        <SummaryItem>
          <SummaryContent>
            <IconContainer>
              <Icon1 />
            </IconContainer>
            <h3>Jaká data se prodávají</h3>
            <p>Data jsou „rodinné stříbro“ firmy. Prodávají se data od zákazníků, přes emailová data až třeba po cookies. </p>
          </SummaryContent>
        </SummaryItem>
        <SummaryItem>
          <SummaryContent>
            <IconContainer>
              <Icon2 />
            </IconContainer>
            <h3>Očekávaný příjem z dat</h3>
            <p>Jaký očekáváte příjem? Ten totiž vždy závisí na byznys modelu. Návratnost investic (ROI) může být několikanásobně vyšší, než máte nyní z nesprávně analyzovaných dat. Dokonce může být příjem vyšší než současné revenue. </p>
          </SummaryContent>
        </SummaryItem>
        <SummaryItem>
          <SummaryContent>
            <IconContainer>
              <Icon3 />
            </IconContainer>
            <h3>Kde se data nakupují</h3>
            <p>Existuje mnoho způsobů, kde data „nakoupit“. Jednou z cest jsou speciální dedikované burzy, databáze nebo léty osvědčený přímý kontakt.  </p>
          </SummaryContent>
        </SummaryItem>
        <SummaryItem>
          <SummaryContent>
            <IconContainer>
              <Icon4 />
            </IconContainer>
            <h3>Technika monetizace</h3>
            <p>Jaká je ta nejlepší technika pro „vyždímání“ maxima z dat? Může to být prodej syrových dat, obohacení dat nebo využijte služby nad daty. </p>
          </SummaryContent>
        </SummaryItem>
        <SummaryItem>
          <SummaryContent>
            <IconContainer>
              <Icon5 />
            </IconContainer>
            <h3>Realtime data přístup</h3>
            <p>Pomůžeme vám se zpracováním dat v reálném čase. Získejte ve stále se vyvíjecím a dynamickém světě náskok před konkurencí a adaptujte se na zvyšující se požadavky na přístup k realtime datům.</p>
          </SummaryContent>
        </SummaryItem>
        <SummaryItem>
          <SummaryContent>
            <IconContainer>
              <Icon6 />
            </IconContainer>
            <h3>Případové studie</h3>
            <p>Nebudeme plkat o teorii, představíme si konkrétní a úspěšné případy. Zařaďte se mezi ty úspěšné.</p>
          </SummaryContent>
        </SummaryItem>
      </SummaryList>
    </div>
  </Summary>
)

export default SummarySection