import React, { Component } from 'react';

import styled from 'styled-components'
import * as style from './style'

const Bar = styled.div`
  width: 100%;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  background: ${style.colors.primary};
  .section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  p {
    margin: 0;
    color: white;
    font-size: 14px;
  }
`;

const CloseButton = styled.button`
  background: ${style.colors.action};
  color: ${style.colors.primary};
  padding: 5px 15px;
  font-size: 14px;
  margin: 0;
  font-weight: bold;
  display: inline-block;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
`;

//the class you are making your component from
class CookieBar extends Component {
  // constructor to set state and bind "this"
  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true
    };
    this.handleClick = this.handleClick.bind(this);
  }

  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  // function to handle the click
   handleClick(e) {
    e.preventDefault();
    this.setCookie('cbclosed', '1', 365);
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }

  componentDidMount() {
    const isClosed = this.getCookie('cbclosed') === '1' ? true : false;
    this.setState(({
      isToggleOn: !isClosed
    }));
  }

  // the render() method to put stuff into the DOM
  render() {
    const CookieNotice = (
      <Bar>
        <div className="section">
          <p>Používáním tohoto webu souhlasíte s použitím souborů cookie.</p>
          <CloseButton onClick={this.handleClick}>OK</CloseButton>
        </div>
      </Bar>
    );
    // the return() to put your default HTML into the DOM
    return (
      <div>
        {this.state.isToggleOn ? CookieNotice : ''}
      </div>
    );
  }
}

export default CookieBar